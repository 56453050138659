import moment from "moment";
import {
    Card,
    Icon,
    Input
} from "antd";
import React, { useEffect, useState } from "react";
import { Constants } from "../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import PodService from "../../../services/pod/pod.service";
import PODForm from "../pod.form/pod.form";

import { useSelector } from "react-redux";
import * as PodLiveService from "../pod-live/service";
import { v4 as uuidv4 } from 'uuid';


const podService = new PodService();

const ConsignmentPOD = ({
    history,
    match
}) => {

    const [formData, setFormData] = useState(false);
    const [consignmentData, setconsignmentData] = useState(false);
    const [drsData, setDrsData] = useState(false);
    const [creating, setCreating] = useState(false);

    const config = useSelector((state) => state.app.configurations)
    const user = JSON.parse(atob(localStorage.user))

    useEffect(_ => {

        const options = {
            enableHighAccuracy: true
        }

        if (match.params.id) {
            podService.getConsignmentData(match.params.id).then((responseData) => {
                var consignment = responseData.data.response.consignments[0];
                if (["OFD", "RTO_OUT", "DRS_LINKED"].indexOf(consignment.consignmentStatus) > -1) {

                    const tempData = {
                        "status": "DEL",
                        "consigneeName": consignment.customer.name,
                        "shipmentCount": consignment.totalShipmentCount,
                        "phone": consignment.customer.phoneNumber,
                        "email": consignment.customer.emailId,
                        "locId": consignment.bookingOfficeLoc.name,
                        "date": moment().format("DD/MM/YYYY"),
                        "codAmount": consignment.payableAmount,
                    }

                    if (user?.location?.id && config?.unblockERPDeliverOptionLocationId) {
                        if (!config?.unblockERPDeliverOptionLocationId?.includes(String(user.location.id)) &&
                            !config?.unblockERPDeliverOptionLocationId?.includes("0")) {
                            tempData.status = "UNDEL"
                        }
                    }

                    setFormData(tempData);
                    setconsignmentData(consignment);
                    setDrsData(responseData.data.response.drses?.[0]);
                }
                else {
                    notifyApiError("Consignment should be in RTO_OUT or OFD or DRS_LINKED status to create POD")
                    history.push("/appv2/pod/dashboard/drs");
                }

            })
        }
    }, []);

    const goBack = () => {
        history.push(`/appv2/pod/dashboard/drs`)
    }

    const submitPOD = (pod) => {

        setCreating(true);
        // Fetching selected relation details.
        const { relations } = JSON.parse(window.atob(localStorage.getItem("_configrations")));
        const selectedRelation = relations.filter((relation) => relation.relationCode == pod.relationShipStatus)

        const uuid = uuidv4()

        let consignmentPODReq = {
            "consignmentPodBO": {
                "podUpdatedDate": moment().valueOf(),
                "drsCode": match.params.drsCode ? match.params.drsCode : drsData?.drsCode,
                "waybillNo": consignmentData.waybillNo,
                "userId": Constants.sessionUser.id,
                "isMobility": false,
                "otpVerified": false,
                "time": moment().valueOf(),
                "shipmentStatus": pod.status,
                "callDetails": []
            },
            "referenceId": uuid
        }

        if (pod.status == 'DEL') {
            if (pod.idProofNo) {
                consignmentPODReq["consignmentPodBO"]['idProofNo'] = pod.idProofNo
            }
            consignmentPODReq["consignmentPodBO"]["idProofType"] = pod.idProofType
            consignmentPODReq["consignmentPodBO"]["collectedAmount"] = pod.codAmount
            consignmentPODReq["consignmentPodBO"]["paymentType"] = pod.paymentType
            consignmentPODReq["consignmentPodBO"]["phoneNumber"] = pod.relationContact
            consignmentPODReq["consignmentPodBO"]["podImpLink"] = pod.podImpLink
            consignmentPODReq["consignmentPodBO"]["receivedBy"] = pod.receivedBy
            consignmentPODReq["consignmentPodBO"]["relation"] = pod.relationShipStatus
            consignmentPODReq["consignmentPodBO"]["relationshipId"] = selectedRelation.length > 0 && selectedRelation[0]?.id ? selectedRelation[0].id : null
        }

        if (pod.remarks) {
            consignmentPODReq["consignmentPodBO"]['remarks'] = pod.remarks
        }

        if (pod.reasonId) {
            consignmentPODReq["consignmentPodBO"]["reasonId"] = pod.reasonId
        }

        let consignmentPodReqList = { "request": [consignmentPODReq] }

        PodLiveService.createPod(consignmentPodReqList)
            .then((data) => {
                if (data?.consignmentPODs?.length && data.consignmentPODs[0]?.status == "FAILED") {
                    notifyApiError(data.consignmentPODs[0]?.reason)
                } else {
                    notifyApiSuccess("POD Created Successfully");
                    history.push("/appv2/pod/dashboard/drs")
                }
                setCreating(false);
            })
            .catch((error) => {
                console.error("Error will calling createPod", error)
                setCreating(false);
            })

    }

    return (
        <div className="flex-column flex-gap-l">
            <div className="flex-box flex-gap-m align-items-center">
                <Icon type="arrow-left" onClick={goBack} />
                <div className="font-size-l-1 text-bold"> Create POD </div>
            </div>
            {
                !match.params.id &&
                <Card>
                    <div className="flex-box column">
                        <h4>Waybill Number</h4>
                        <Input
                            placeholder="Enter Waybill Number"
                            onPressEnter={e => {
                                const waybillNo = e.target.value;
                                podService.getWaybillData(waybillNo).then(waybill => {
                                    var { consignment } = waybill.data.response;
                                    if (consignment.id) {
                                        if (["OFD", "RTO_OUT", "DRS_LINKED"].indexOf(consignment.consignmentStatus) > -1) {
                                            const tempData = {
                                                "status": "DEL",
                                                "consigneeName": consignment.customer.name,
                                                "shipmentCount": consignment.totalShipmentCount,
                                                "phone": consignment.customer.phoneNumber,
                                                "email": consignment.customer.emailId,
                                                "locId": consignment.bookingOfficeLoc.name,
                                                "date": moment().format("DD/MM/YYYY"),
                                                "codAmount": consignment.payableAmount,
                                            }
                                            setFormData(tempData);
                                            setDrsData(waybill.data.response.drses[0]);
                                            setconsignmentData(consignment);
                                        }
                                        else {
                                            notifyApiError("Consignment should be in RTO_OUT or OFD status to create POD!")
                                        }
                                    }
                                    else {
                                        notifyApiError("Waybill not found!")
                                    }
                                })
                            }}
                        />
                    </div>
                </Card>
            }
            {
                <PODForm setFormData={setFormData}
                    creating={creating}
                    submitPOD={submitPOD}
                    formData={formData}
                    goBack={goBack}
                    consignmentData={consignmentData}
                />
            }
        </div>
    )
}

export default ConsignmentPOD;