import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../pod-drs-closure.styles.scss";
import '../../../../assets/styles/common.scss';
import * as drsClosureAction from "../action";
import { notifyApiError, notifyApiSuccess } from "../../../../common/utils/utils";


const CloseDrsModal = ({ open, handleModalClose, handleModalConfirm, modalData,
    toggleLoader }) => {

    const [loader, setLoader] = useState(false);

    const handleCancel = () => {
        handleModalClose()
    }

    const handleConfirm = () => {

        toggleLoader(true)
        setLoader(true)

        const reqBody = {
            "cashCollected": modalData.collectedCodAmount.toString()
        }

        drsClosureAction.closeDrs(modalData.feId, reqBody)
            .then((data) => {
                setLoader(false)
                notifyApiSuccess(data)
                handleModalConfirm()
            })
            .catch((error) => {
                setLoader(false)
                notifyApiError(error?.errors)
                console.error("closeDrs api failed with following error", error)
            })

        toggleLoader(false)

    }

    return (
        <>
            <Modal
                visible={open}
                footer={[
                    <Button onClick={handleCancel}>Cancel</Button>,
                    <Button onClick={handleConfirm} loading={loader} className="lsn-btn-primary">
                        Close DRS
                    </Button>
                ]}
                closable={false}
                className="close-drs-modal"
            >
                <div><b>NOTE:</b> Please confirm the amount that you have collected
                    from {modalData.feName}</div>

                <ul className="drs-list-data">
                    <li>All the returned Waybills will move to Inventory Waybills.</li>
                    <li>POD will no longer be allowed against this DRS.</li>
                </ul>

                <div className="flex-box flex-gap-l align-items-center">
                    <div>Collected Cash:</div>
                    <Input value={"₹ " + (modalData.collectedCodAmount ? modalData.collectedCodAmount.toString() : "0")}
                        disabled={true} className="flex-1" />
                </div>
            </Modal>
        </>
    )

}

export default CloseDrsModal;