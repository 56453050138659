export const pModules = {
  leads: {
    title: "LEADS",
    subModule: {
      lead: "LEAD",
      runSheet: "RUN_SHEET",
      assignPickup: "ASSIGN_PICKUP",
      pickupPlan: "PICKUP_PLAN",
      managePickup: "MANAGE_PICKUP",
      manageReversePickup: "MANAGE_REVERSE_PICKUP"
    }
  },
  inboud: {
    title: "INBOUND",
    subModule: {
      scan: "SCAN",
      expected: "EXPECTED",
      exceptions: "EXCEPTIONS",
      newscan: "NEW_SCAN",
      print_history: "PRINT_HISTORY"
    }
  },
  inboudView: {
    title: "INBOUND_VIEW",
    subModule: {
      scan: "SCAN",
      newscan: "NEW_SCAN",
      booking: "BOOKING"
    }
  },
  load: {
    title: "LOAD_VIEW",
    subModule: {
      expected: "EXPECTED",
      exceptions: "EXCEPTIONS"
    }
  },
  inventory: {
    title: "INVENTORY",
    subModule: {
      awb: "AWB",
      waybill: "WAYBILL",
      drs: "DRS",
      bulkCreate: "BULK_CREATE",
      manifest: "MANIFEST",
      manifest_partner: "MANIFEST_PARTNER",
      manifest_waybill: "MANIFEST_WAYBILL",
      hire_truck: "HIRE_TRUCK",
      bag: "BAG",
      live_loads: "LIVE_LOADS",
      pickup_assistance: "PICKUP-ASSISTANCE",
      drsdraft: "DRAFT",
      closure: "CLOSURE",
      outscan: "OUTSCAN",
      label_printing: "LABEL_PRINTING",
      exceptions: "EXCEPTIONS"
    }
  },
  tracking: {
    title: "TRACKING",
    subModule: {
      linehaul: "LINEHAUL",
      intracity: "INTRACITY",
      tracking: "TRACKING",
      oldTracking: "OLD_TRACKING",
      newTracking: "VEHICLE_TRACKING",
      gmaptracking: "BEECON_MAPS",
      heliosTracking: "HELIOS_TRACKING",
      heliosVehicleTracking: "HELIOS_VEHICLE_TRACKING"
    }
  },
  trips: {
    title: "TRIPS",
    subModule: {
      connection_sheet: "CONNECTION_SHEET",
      inbound: "INBOUND_TRIPS",
      outbound: "OUTBOUND_TRIPS"
    }
  },
  dashboard: {
    title: "DASHBOARD",
    subModule: {
      BRANCH_SUMMARY: "BRANCH_SUMMARY",
      SLA: "SLA",
      COOLING_PERIOD: "COOLING_PERIOD",
      POD: "POD",
      WAYBILL_DATA: "WAYBILL_DATA",
      CSAT_FEEDBACK: "CSAT_FEEDBACK",
      ESCAL_CSAT: "ESCAL_CSAT",
      REVENUE: "REVENUE",
      GPS_TRACKING: "GPS_TRACKING",
      SERVICEABILITY: "SERVICEABILITY",
      CFT: "CFT",
      EwaybillAvailability: "EwaybillAvailability",
      OPSKPI: "OPSKPI",
      LASTMILE_USAGE: "LASTMILE_USAGE",
      REALTIME_USAGE: "REALTIME_USAGE",
      USER_MISTAKES: "USER_MISTAKES",
      POD_HEALTH: "POD_HEALTH"
    }
  },
  pod: {
    title: "POD",
    subModule: {
      drs: "DRS",
      hire_truck: "HIRE_TRUCK",
      drs_closure: "DRS_CLOSURE",
      suspicious: "SUSPICIOUS_UNDEL",
      live: "LIVE",
      otp: "OTP"
    }
  },
  cod_new: {
    title: "COD_NEW",
    subModule: {
      collect: "COLLECT",
      remit: "REMIT",
      remittance: "REMITTANCE"
    }
  },
  rto: {
    title: "RTO",
    subModule: {
      waybill: "WAYBILL",
      drs: "DRS",
      manifest: "MANIFEST",
      manifest_partner: "MANIFEST_PARTNER",
      manifest_waybill: "MANIFEST_WAYBILL",
      bag: "BAG",
      hire_truck: "HIRE_TRUCK",
      rto_eligible: "RTO_ELIGIBLE",
      drs_closure: "DRS_CLOSURE",
      rto_bulk_upload: "RTO_BULK_UPLOAD",
    }
  },
  settings: {
    title: "SETTINGS",
    subModule: {
      customer: "CUSTOMER",
      vendor: "VENDOR",
      route: "ROUTE",
      user: "USER",
      location: "LOCATION",
      asset: "ASSET",
      vehicle: "VEHICLE",
      zone: "ZONE",
      pincode: "PINCODE",
      customer_invite: "CUSTOMER_INVITE",
      zoho: "ZOHO",
      custom_config: { title: "PARTNERS ONBOARDING", configKey: "authorizedUserIdsToUpdateApplicationConfig" },
      partners_onboarding: { title: "PARTNERS ONBOARDING", configKey: "partnerCreation" }
    }
  },
  importExport: {
    title: "IMPORT_EXPORT",
    subModule: {
      import: "IMPORT",
      export: "EXPORT",
      docketToFinishTrip: "DOCKET_TO_FINISH_TRIP"
    }
  },
  reports: {
    title: "REPORTS",
    subModule: {
      reports: "REPORTS",
      ingest: "INGEST",
      manifestSheet: "MANIFEST_SHEET",
      new_reports: "NEW_REPORTS",
      utility: "UTILITY"
    }
  },
  audits: {
    title: "AUDITS",
    subModule: {
      audit: "AUDIT"
    }
  },
  manage_fleet: {
    title: "MANAGE_FLEET",
    subModule: {
      map: "MAP",
      kyc: "KYC",
    }
  },
  digital_payment: {
    title: "DIGITAL_PAYMENT",
    subModule: {
      payment_status: "PAYMENT_STATUS"
    }
  },
  verification: {
    title: "VERIFICATION",
    subModule: {
      trips: "TRIPS",
      docket: "DOCKET",
      pod: "POD",
      contract: "CONTRACT"
    }
  },
  contract: {
    title: "CONTRACT",
    subModule: {
      customer: "CUSTOMERBASIC",
      locations: "LOCATIONS",
      zones: "ZONES",
      shippers: "SHIPPERS",
      lanes: "LANES",
      contractdetails: "CONTRACTDETAILS"
    }
  },
  monitoring_reports: {
    title: "MONITORING_REPORTS",
    subModule: {
      first_mile: "FIRST_MILE",
      last_mile: "LAST_MILE",
      mid_mile: "MID_MILE",
      first_mile_admin: "FIRST_MILE_ADMIN",
      performance_overview: "PERFORMANCE_OVERVIEW",
      admin_monitoring: "ADMIN_MONITORING",
      lm_hub_visibilty: "LM_HUB_VISIBILITY",
      sc_hub_visibilty: "SC_HUB_VISIBILITY",
      fm_hub_visibility: "FM_HUB_VISIBILITY",
      sc_visibility: "SC_VISIBILITY",
      embedded_view: "EMBEDDED_VIEW"
    }
  },
  lm_operation: {
    title: "LM_OPERATION",
    subModule: {
      live_drs: "LIVE_DRS"
    }
  },
  sc_operation: {
    title: "SC_OPERATION",
    subModule: {
      daf: "DAF"
    }
  },
  fm_operation: {
    title: "FM_OPERATION",
    subModule: {
      forward: "FORWARD",
      rto: "RTO"
    }
  },
  billing: {
    title: "BILLING",
    subModule: {
      fwd: "FORWARD",
      rto: "RTO",
      cross_dock: "CROSS_DOCK"
    }
  }
};

export const paTags = {
  read: "READ",
  create: "CREATE",
  update: "UPDATE",
  delete: "DELETE",
  view: "VIEW",
  bulkCreate: "BULK_CREATE",
  bulkUpdate: "BULK_UPDATE",
  waybill_scan: "WAYBILL_SCAN",
  barcode_scan: "BARCODE_SCAN",
  orn_scan: "ORN_SCAN",
  bulk_booking: "BULK_BOOKING",
  bulk_inscan: "BULK_INSCAN",
  flipkart_inscan_api: "FLIPKART_INSCAN_API",
  print_waybill: "PRINT_WAYBILL",
  locationSelection: "LOCATION_SELECTION",
  bulk_pod: "BULK_POD",
  readERP: "READ_ERP",
  invite: "INVITE",
  hpod: "HPOD",
  view_unblock: "VIEW_UNBLOCK",
  view_shippers: "SHIPPERS_VIEW",
  create_shippers: "SHIPPERS_CREATE",
  update_shippers: "SHIPPERS_UPDATE",
  status_shippers: "SHIPPERS_STATUS",
  ratecard_shippers: "SHIPPERS_RATECARD",
  undel_return: "UNDEL_RETRUN",
  coloader: "COLOADER",
  deactivate: "DEACTIVATE",
  refresh: "REFRESH",
  reverify: "REVERIFY",
  upload: "UPLOAD",
  approve: "APPROVE",
  edit: "EDIT",
  contract_view: "CONTRACT_VIEW",
  reuseManifest: "REUSE_MANIFEST",
  forwardManifest: "FORWARD_MANIFEST",
};

export const permissionTags = {
  leads: {
    module: { module: pModules.leads.title },
    lead: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead
      },
      create: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead,
        action: paTags.create
      },
      read: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead,
        action: paTags.read
      },
      delete: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead,
        action: paTags.delete
      },
      update: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead,
        action: paTags.update
      },
      view: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.lead,
        action: paTags.view
      }
    },
    runSheet: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.runSheet
      },
      create: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.runSheet,
        action: paTags.create
      },
      read: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.runSheet,
        action: paTags.read
      },
      delete: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.runSheet,
        action: paTags.delete
      },
      update: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.runSheet,
        action: paTags.update
      }
    },
    assignPickup: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.assignPickup
      },
      bulkUpdate: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.assignPickup,
        action: paTags.bulkUpdate
      },
    },
    pickupPlan: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.pickupPlan
      },
      read: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.pickupPlan,
        action: paTags.read
      }
    },
    managePickup: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.managePickup
      },
      create: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.managePickup,
        action: paTags.create
      },
      read: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.managePickup,
        action: paTags.read
      },
      delete: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.managePickup,
        action: paTags.delete
      },
      update: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.managePickup,
        action: paTags.update
      }
    },
    manageReversePickup: {
      subModule: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.manageReversePickup
      },
      create: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.manageReversePickup,
        action: paTags.create
      },
      read: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.manageReversePickup,
        action: paTags.read
      },
      delete: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.manageReversePickup,
        action: paTags.delete
      },
      update: {
        module: pModules.leads.title,
        subModule: pModules.leads.subModule.manageReversePickup,
        action: paTags.update
      }
    },
  },
  inbound: {
    module: { module: pModules.inboud.title },
    scan: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.read
      },
      create: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.create
      },
      delete: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.delete
      },
      update: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.scan,
        action: paTags.update
      }
    },
    expected: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.expected
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.expected,
        action: paTags.read
      }
    },
    exceptions: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.exceptions
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.exceptions,
        action: paTags.read
      }
    },
    print_history: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history
      },
      create: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.create
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.read
      },
      delete: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.delete
      },
      update: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.print_history,
        action: paTags.update
      }
    },
    newscan: {
      subModule: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan
      },
      read: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.read
      },
      waybill_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.waybill_scan
      },
      barcode_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.barcode_scan
      },
      orn_scan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.orn_scan
      },
      bulk_booking: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_booking
      },
      bulk_inscan: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_inscan
      },
      flipkart_inscan_api: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.flipkart_inscan_api
      },
      print_waybill: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.print_waybill
      },
      locationSelection: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.locationSelection
      },
      bulk_pod: {
        module: pModules.inboud.title,
        subModule: pModules.inboud.subModule.newscan,
        action: paTags.bulk_pod
      },
    }
  },
  inboundView: {
    module: { module: pModules.inboudView.title },
    scan: {
      subModule: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.scan },
      read: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.scan, action: paTags.read },
      create: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.scan, action: paTags.create },
      delete: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.scan, action: paTags.delete },
      update: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.scan, action: paTags.update }
    },
    newscan: {
      subModule: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan
      },
      read: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.read
      },
      waybill_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.waybill_scan
      },
      barcode_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.barcode_scan
      },
      orn_scan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.orn_scan
      },
      bulk_booking: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_booking
      },
      bulk_inscan: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_inscan
      },
      flipkart_inscan_api: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.flipkart_inscan_api
      },
      print_waybill: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.print_waybill
      },
      locationSelection: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.locationSelection
      },
      bulk_pod: {
        module: pModules.inboudView.title,
        subModule: pModules.inboudView.subModule.newscan,
        action: paTags.bulk_pod
      },
    },
    booking: {
      subModule: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.booking },
      read: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.booking, action: paTags.read },
      create: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.booking, action: paTags.create },
      delete: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.booking, action: paTags.delete },
      update: { module: pModules.inboudView.title, subModule: pModules.inboudView.subModule.booking, action: paTags.update }
    },

  },
  load: {
    module: { module: pModules.load.title },
    expected: {
      subModule: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.expected
      },
      read: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.expected,
        action: paTags.read
      }
    },
    exceptions: {
      subModule: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.exceptions
      },
      read: {
        module: pModules.load.title,
        subModule: pModules.load.subModule.exceptions,
        action: paTags.read
      }
    }
  },
  inventory: {
    module: { module: pModules.inventory.title },
    awb: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.awb,
        action: paTags.update
      }
    },
    waybill: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.waybill
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.waybill,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.waybill,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.waybill,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.waybill,
        action: paTags.update
      }
    },
    exceptions: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.exceptions
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.exceptions,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.exceptions,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.exceptions,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.exceptions,
        action: paTags.update
      }
    },
    drs: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs,
        action: paTags.update
      },
      bulk_create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drs,
        action: paTags.bulkCreate
      }
    },
    manifest: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest,
        action: paTags.update
      },
      bulkCreate: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest,
        action: paTags.bulkCreate
      },
    },
    manifest_partner: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.update
      },
      bulkCreate: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.bulkCreate
      },
      reuseManifest: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_partner,
        action: paTags.reuseManifest
      },
    },

    manifest_waybill: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_waybill
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_waybill,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_waybill,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_waybill,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.manifest_waybill,
        action: paTags.update
      }
    },
    hire_truck: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck,
        action: paTags.update
      },
      forwardManifest: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.hire_truck,
        action: paTags.forwardManifest
      },
    },
    bag: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.bag
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.bag,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.bag,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.bag,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.bag,
        action: paTags.update
      }
    },
    live_loads: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.live_loads
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.live_loads,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.live_loads,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.live_loads,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.live_loads,
        action: paTags.update
      }
    },
    pickup_assistance: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.pickup_assistance
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.pickup_assistance,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.pickup_assistance,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.pickup_assistance,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.pickup_assistance,
        action: paTags.update
      }
    },
    drsdraft: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drsdraft,
      },
      create: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drsdraft,
        action: paTags.create
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drsdraft,
        action: paTags.read
      },
      delete: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drsdraft,
        action: paTags.delete
      },
      update: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.drsdraft,
        action: paTags.update
      }
    },
    closure: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.closure,
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.closure,
        action: paTags.read
      },
    },
    outscan: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.outscan,
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.outscan,
        action: paTags.read
      },
    },
    label_printing: {
      subModule: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.label_printing,
      },
      read: {
        module: pModules.inventory.title,
        subModule: pModules.inventory.subModule.label_printing,
        action: paTags.read
      },
    },
  },
  tracking: {
    module: { module: pModules.tracking.title },
    linehaul: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.linehaul
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.linehaul,
        action: paTags.read
      }
    },
    intracity: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.intracity
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.intracity,
        action: paTags.read
      }
    },
    tracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking,
        action: paTags.read
      }
    },
    newtracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.newTracking
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.newTracking,
        action: paTags.read
      }
    },
    gmaptracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.gmaptracking
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.gmaptracking,
        action: paTags.read
      }
    },
    heliostracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosTracking
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosTracking,
        action: paTags.read
      }
    },
    heliosVehicleTracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosVehicleTracking
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.heliosVehicleTracking,
        action: paTags.read
      }
    },
  },
  trips: {
    module: { module: pModules.trips.title },
    connection_sheet: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.connection_sheet
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.connection_sheet,
        action: paTags.read
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.connection_sheet,
        action: paTags.create
      },
      delete: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.connection_sheet,
        action: paTags.delete
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.connection_sheet,
        action: paTags.update
      }
    },
    inbound: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.inbound
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.inbound,
        action: paTags.read
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.inbound,
        action: paTags.create
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.inbound,
        action: paTags.update
      }
    },
    outbound: {
      subModule: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.outbound
      },
      read: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.outbound,
        action: paTags.read
      },
      create: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.outbound,
        action: paTags.create
      },
      update: {
        module: pModules.trips.title,
        subModule: pModules.trips.subModule.outbound,
        action: paTags.update
      }
    }
  },
  verification: {
    module: { module: pModules.verification.title },
    trips: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.read
      },
      create: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.create
      },
      delete: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.delete
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.update
      },
      reverify: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.trips,
        action: paTags.reverify
      },
    },
    docket: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.read
      },
      upload: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.upload
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.docket,
        action: paTags.update
      },
    },
    contract: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.read
      },
      update: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.update
      },
      approve: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.contract,
        action: paTags.approve
      }
    },
    pod: {
      subModule: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod
      },
      read: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.read
      },
      edit: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.edit
      },
      delete: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.delete
      },
      create: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.create
      },
      approve: {
        module: pModules.verification.title,
        subModule: pModules.verification.subModule.pod,
        action: paTags.approve
      },
    },
  },
  contract: {
    module: { module: pModules.contract.title },
    customer: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.customer,
        action: paTags.delete
      },
    },
    shippers: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.shippers,
        action: paTags.delete
      },
    },
    zones: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.zones,
        action: paTags.delete
      },
    },
    locations: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.locations,
        action: paTags.delete
      },
    },
    lanes: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.lanes,
        action: paTags.delete
      },
    },
    contractdetails: {
      subModule: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails
      },
      view: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.view
      },
      create: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.create
      },
      update: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.update
      },
      delete: {
        module: pModules.contract.title,
        subModule: pModules.contract.subModule.contractdetails,
        action: paTags.delete
      }
    }
  },
  pod: {
    module: { module: pModules.pod.title },
    drs: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs },
      create: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs, action: paTags.create },
      update: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs, action: paTags.update },
      delete: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs, action: paTags.delete },
      read: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs, action: paTags.read }
    },
    hire_truck: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.hire_truck },
      create: { module: pModules.pod.title, subModule: pModules.pod.subModule.hire_truck, action: paTags.create },
      update: { module: pModules.pod.title, subModule: pModules.pod.subModule.hire_truck, action: paTags.update },
      delete: { module: pModules.pod.title, subModule: pModules.pod.subModule.hire_truck, action: paTags.delete },
      read: { module: pModules.pod.title, subModule: pModules.pod.subModule.hire_truck, action: paTags.read }
    },
    drs_closure: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs_closure },
      create: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs_closure, action: paTags.create },
      update: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs_closure, action: paTags.update },
      delete: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs_closure, action: paTags.delete },
      read: { module: pModules.pod.title, subModule: pModules.pod.subModule.drs_closure, action: paTags.read }
    },
    suspicious: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.suspicious },
      create: { module: pModules.pod.title, subModule: pModules.pod.subModule.suspicious, action: paTags.create },
      update: { module: pModules.pod.title, subModule: pModules.pod.subModule.suspicious, action: paTags.update },
      delete: { module: pModules.pod.title, subModule: pModules.pod.subModule.suspicious, action: paTags.delete },
      read: { module: pModules.pod.title, subModule: pModules.pod.subModule.suspicious, action: paTags.read }
    },
    live: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.live },
      create_pod: { module: pModules.pod.title, subModule: pModules.pod.subModule.live, action: paTags.update },
    },
    otp: {
      subModule: { module: pModules.pod.title, subModule: pModules.pod.subModule.otp },
      read: { module: pModules.pod.title, subModule: pModules.pod.subModule.otp, action: paTags.read },
    }
  },
  cod_new: {
    module: { module: pModules.cod_new.title },
    collect: {
      subModule: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.collect
      },
      create: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.collect,
        action: paTags.create
      },
      update: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.collect,
        action: paTags.update
      },
      delete: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.collect,
        action: paTags.delete
      },
      read: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.collect,
        action: paTags.read
      }
    },
    remit: {
      subModule: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remit
      },
      create: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remit,
        action: paTags.create
      },
      update: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remit,
        action: paTags.update
      },
      delete: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remit,
        action: paTags.delete
      },
      read: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remit,
        action: paTags.read
      }
    },
    remittance: {
      subModule: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remittance
      },
      read: {
        module: pModules.cod_new.title,
        subModule: pModules.cod_new.subModule.remittance,
        action: paTags.read
      }
    }
  },
  rto: {
    module: { module: pModules.rto.title },
    waybill: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.waybill },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.waybill, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.waybill, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.waybill, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.waybill, action: paTags.update }
    },
    drs: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs, action: paTags.update }
    },
    drs_closure: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs_closure },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs_closure, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs_closure, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs_closure, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.drs_closure, action: paTags.update }
    },
    manifest: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest, action: paTags.update }
    },
    manifest_partner: {
      subModule: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner
      },
      create: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.create
      },
      read: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.read
      },
      delete: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.delete
      },
      update: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.update
      },
      bulkCreate: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.bulkCreate
      },
      reuseManifest: {
        module: pModules.rto.title,
        subModule: pModules.rto.subModule.manifest_partner,
        action: paTags.reuseManifest
      },
    },
    manifest_waybill: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest_waybill },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest_waybill, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest_waybill, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest_waybill, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.manifest_waybill, action: paTags.update }
    },
    bag: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.bag },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.bag, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.bag, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.bag, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.bag, action: paTags.update }
    },
    hire_truck: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.hire_truck },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.hire_truck, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.hire_truck, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.hire_truck, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.hire_truck, action: paTags.update }
    },
    rto_eligible: {
      subModule: { module: pModules.rto.title, subModule: pModules.rto.subModule.rto_eligible },
      create: { module: pModules.rto.title, subModule: pModules.rto.subModule.rto_eligible, action: paTags.create },
      read: { module: pModules.rto.title, subModule: pModules.rto.subModule.rto_eligible, action: paTags.read },
      delete: { module: pModules.rto.title, subModule: pModules.rto.subModule.rto_eligible, action: paTags.delete },
      update: { module: pModules.rto.title, subModule: pModules.rto.subModule.rto_eligible, action: paTags.update }
    }
  },
  settings: {
    module: { module: pModules.settings.title },
    customer: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.view },
      invite: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.invite },
      shippers: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.view_shippers },
      create_shippers: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.create_shippers },
      update_shippers: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.update_shippers },
      status_shippers: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.status_shippers },
      ratecard_shippers: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.ratecard_shippers },
      contract_view: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer, action: paTags.contract_view },
    },
    vendor: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor, action: paTags.read },
      unblock: { module: pModules.settings.title, subModule: pModules.settings.subModule.vendor, action: paTags.view_unblock }
    },
    route: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.route },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.view },
      refresh: { module: pModules.settings.title, subModule: pModules.settings.subModule.route, action: paTags.refresh }
    },
    user: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.user },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.user, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.user, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.user, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.user, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.user, action: paTags.view }
    },
    location: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.location },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.location, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.location, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.location, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.location, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.location, action: paTags.view }
    },
    asset: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.asset },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.asset, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.asset, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.asset, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.asset, action: paTags.read }
    },
    vehicle: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.vehicle, action: paTags.view }
    },
    zone: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone, action: paTags.read },
      view: { module: pModules.settings.title, subModule: pModules.settings.subModule.zone, action: paTags.view }
    },
    zoho: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.zoho },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.zoho, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.zoho, action: paTags.update },

      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.zoho, action: paTags.read },

    },
    pincode: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.pincode },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.pincode, action: paTags.update }
    },
    partners_onboarding: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.partners_onboarding },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.partners_onboarding, action: paTags.create }
    },
    customer_invite: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer_invite },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer_invite, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer_invite, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer_invite, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.customer_invite, action: paTags.read }
    },
    custom_config: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.custom_config },
      create: { module: pModules.settings.title, subModule: pModules.settings.subModule.custom_config, action: paTags.create },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.custom_config, action: paTags.update },
      delete: { module: pModules.settings.title, subModule: pModules.settings.subModule.custom_config, action: paTags.delete },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.custom_config, action: paTags.read }
    },
    ratecard_config: {
      subModule: { module: pModules.settings.title, subModule: pModules.settings.subModule.ratecard_config },
      update: { module: pModules.settings.title, subModule: pModules.settings.subModule.ratecard_config, action: paTags.update },
      read: { module: pModules.settings.title, subModule: pModules.settings.subModule.ratecard_config, action: paTags.read }
    },
    slaRules: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.read
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.create
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.update
      },
      deactivate: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.deactivate
      },

    }
  },
  importExport: {
    module: { module: pModules.importExport.title },
    import: {
      subModule: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.import },
      create: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.import, action: paTags.read }
    },
    export: {
      subModule: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.export },
      create: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.export, action: paTags.read }
    },
    docketToFinishTrip: {
      subModule: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.docketToFinishTrip },
      create: { module: pModules.importExport.title, subModule: pModules.importExport.subModule.docketToFinishTrip, action: paTags.read }
    },
  },
  reports: {
    module: { module: pModules.reports.title },
    reports: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.reports
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.reports,
        action: paTags.read
      }
    },
    ingest: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.ingest
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.ingest,
        action: paTags.read
      }
    },
    manifestSheet: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.manifestSheet
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.manifestSheet,
        action: paTags.read
      }
    },
    new_reports: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.new_reports
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.new_reports,
        action: paTags.read
      }
    },
    utility: {
      subModule: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.utility
      },
      read: {
        module: pModules.reports.title,
        subModule: pModules.reports.subModule.utility,
        action: paTags.read
      }
    }
  },
  dashboard: {
    module: { module: pModules.dashboard.title },
    BRANCH_SUMMARY: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.BRANCH_SUMMARY },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.BRANCH_SUMMARY, action: paTags.read }
    },
    SLA: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.SLA },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.SLA, action: paTags.read }
    },
    COOLING_PERIOD: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.COOLING_PERIOD },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.COOLING_PERIOD, action: paTags.read }
    },
    POD: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.POD },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.POD, action: paTags.read }
    },
    WAYBILL_DATA: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.WAYBILL_DATA },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.WAYBILL_DATA, action: paTags.read }
    },
    CSAT_FEEDBACK: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.CSAT_FEEDBACK },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.CSAT_FEEDBACK, action: paTags.read }
    },
    ESCAL_CSAT: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.ESCAL_CSAT },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.ESCAL_CSAT, action: paTags.read }
    },
    REVENUE: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.REVENUE },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.REVENUE, action: paTags.read }
    },
    GPS_TRACKING: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.GPS_TRACKING },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.GPS_TRACKING, action: paTags.read }
    },
    SERVICEABILITY: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.SERVICEABILITY },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.SERVICEABILITY, action: paTags.read }
    },
    OPSKPI: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.OPSKPI },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.OPSKPI, action: paTags.read }
    },
    EwaybillAvailability: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.EwaybillAvailability },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.EwaybillAvailability, action: paTags.read }
    },
    CFT: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.CFT },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.CFT, action: paTags.read }
    },
    LASTMILE_USAGE: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.LASTMILE_USAGE },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.LASTMILE_USAGE, action: paTags.read }
    },
    REALTIME_USAGE: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.REALTIME_USAGE },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.REALTIME_USAGE, action: paTags.read }
    },
    USER_MISTAKES: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.USER_MISTAKES },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.USER_MISTAKES, action: paTags.read }
    },
    POD_HEALTH: {
      subModule: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.POD_HEALTH },
      read: { module: pModules.dashboard.title, subModule: pModules.dashboard.subModule.POD_HEALTH, action: paTags.read }
    },
  },
  audits: {
    module: { module: pModules.audits.title },
    audit: {
      subModule: { module: pModules.audits.title, subModule: pModules.audits.subModule.audit },
      create: { module: pModules.audits.title, subModule: pModules.audits.subModule.audit, action: paTags.create },
      read: { module: pModules.audits.title, subModule: pModules.audits.subModule.audit, action: paTags.read },
      delete: { module: pModules.audits.title, subModule: pModules.audits.subModule.audit, action: paTags.delete },
      update: { module: pModules.audits.title, subModule: pModules.audits.subModule.audit, action: paTags.update }
    }
  },
  manage_fleet: {
    module: { module: pModules.manage_fleet.title },
    map: {
      subModule: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.map
      },
      read: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.map,
        action: paTags.read
      }
    },
    kyc: {
      subModule: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc
      },
      read: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.read
      },
      create: { module: pModules.manage_fleet.title, subModule: pModules.manage_fleet.subModule.kyc, action: paTags.create },
      update: { module: pModules.manage_fleet.title, subModule: pModules.manage_fleet.subModule.kyc, action: paTags.update },
      delete: { module: pModules.manage_fleet.title, subModule: pModules.manage_fleet.subModule.kyc, action: paTags.delete },
      view: { module: pModules.manage_fleet.title, subModule: pModules.manage_fleet.subModule.kyc, action: paTags.view }
    }
  },
  digital_payment: {
    module: { module: pModules.digital_payment.title },
    payment_status: {
      subModule: { module: pModules.digital_payment.title, subModule: pModules.digital_payment.subModule.payment_status },
      read: { module: pModules.digital_payment.title, subModule: pModules.digital_payment.subModule.payment_status, action: paTags.read },
    },
  },
  monitoring_reports: {
    module: { module: pModules.monitoring_reports.title },
    first_mile: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.first_mile },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.first_mile, action: paTags.read },
    },
    last_mile: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.last_mile },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.last_mile, action: paTags.read },
    },
    mid_mile: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.mid_mile },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.mid_mile, action: paTags.read },
    },
    first_mile_admin: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.first_mile_admin },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.first_mile_admin, action: paTags.read },
    },
    performance_overview: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.performance_overview },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.performance_overview, action: paTags.read },
    },
    admin_monitoring: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.admin_monitoring },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.admin_monitoring, action: paTags.read },
    },
    lm_hub_visibilty: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.lm_hub_visibilty },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.lm_hub_visibilty, action: paTags.read },
    },
    sc_hub_visibilty: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.sc_hub_visibilty },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.sc_hub_visibilty, action: paTags.read },
    },
    fm_hub_visibility: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.fm_hub_visibility },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.fm_hub_visibility, action: paTags.read },
    },
    sc_visibility: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.sc_visibility },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.sc_visibility, action: paTags.read },
    },
    embedded_view: {
      subModule: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.embedded_view },
      read: { module: pModules.monitoring_reports.title, subModule: pModules.monitoring_reports.subModule.embedded_view, action: paTags.read },
    }
  },
  lm_operation: {
    module: { module: pModules.lm_operation.title },
    live_drs: {
      subModule: {
        module: pModules.lm_operation.title,
        subModule: pModules.lm_operation.subModule.live_drs
      },
      read: {
        module: pModules.lm_operation.title,
        subModule: pModules.lm_operation.subModule.live_drs,
        action: paTags.read
      },
      update: {
        module: pModules.leads.title,
        subModule: pModules.lm_operation.subModule.live_drs,
        action: paTags.update
      }
    }
  },
  billing: {
    module: { module: pModules.billing.title }
  }
};

export const LsnModules = {
  LEADS: "LEADS",
  INBOUND: "INBOUND",
  LOAD: "LOAD",
  INVENTORY: "INVENTORY",
  AUDITS: "AUDITS",
  TRACKING: "TRACKING",
  DASHBOARD: "DASHBOARD",
  POD: "POD",
  COD: "COD",
  RTO: "RTO",
  SETTINGS: "SETTINGS",
  REPORTS: "REPORTS",
  MANAGE_FLEET: "MANAGE_FLEET",
  DIGITAL_PAYMENT: "DIGITAL_PAYMENT",
  MONITORING_REPORTS: "MONITORING_REPORTS",
  LM_OPERATION: "LM_OPERATION",
  BILLING: "BILLING"
}
