import { apiService } from "../../../common/apiService/service";

export const fetchReversePickupSparkline = (params) => {
    return apiService.get("lm/pickup/manage/sparkline", params)
}

export const fetchFilteredWaybillList = (payload) => {
    return apiService.post("lm/pickup/manage/list", payload)
}

export const fetchFilterOptionsData = (payload) => {
    return apiService.post("lm/pickup/manage/filters", payload)
}

export const triggerLeadAllocation = (payload) => {
    return apiService.post("lm/pickup/manage/allocate", payload)
}

export const triggerWaybillListDownload = (payload) => {
    return apiService.post("lm/pickup/manage/download", payload)
}

export const triggerWaybillScan = (payload) => {
    return apiService.post("b2b/v1/consignments/scan", payload)
}

export const fetchprintLabelData = (waybillNo) => {
    return apiService.get("lm/pickup/manage/waybill/"+waybillNo+"/print")
}

export const fetchFEList = () => {
    return apiService.get("fefmlm/riders");
}