import { Button, Card, Table, Input } from "antd";
import React, { useEffect, useState } from "react";
import { capitalizeWordsAfterHyphen } from "../../../common/utils/utils";
import { fetchUtilityReport } from "./action";
import "./utility.scss";

const UtilityReport = () => {

    const columns = [
        // {
        //     title: 'Sl. No',
        //     dataIndex: "resourceId",
        //     key: 1,
        //     render: (_, __, index) => index + 1,
        //     width: "10%"
        // },
        {
            title: 'Report Name',
            dataIndex: 'reportName',
            key: 2,
            render: (data, row) => {
                return <div>{capitalizeWordsAfterHyphen(data)}</div>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 3
        },
        {
            title: 'Action',
            dataIndex: 'resourceId',
            key: 4,
            render: (id, row) => {
                return <div className='flex-box justify-content-center'>
                    <Button type="primary" className="lsn-btn-primary"
                        onClick={() => handleViewReport(row)}>
                        View Report
                    </Button>
                </div>
            }
        }
    ]

    const [utilityReportData, setUtilityReportData] = useState({})
    const [reportName, setReportName] = useState(null)

    useEffect(() => {
        getPaginatedData(1)
    }, [])

    const getPaginatedData = (pageNo, reportName) => {

        const params = {
            queryParams: {
                pageNo: pageNo, pageSize: 10,
            }
        }

        if (reportName) params.queryParams.reportName = reportName

        fetchUtilityReport(
            params
        )
            .then((res) => {
                setUtilityReportData(res)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const onPageChange = (pageNo) => {
        getPaginatedData(pageNo, reportName)
    }

    const handleViewReport = (rowData) => {

        const reportUrl = `/appv2/reports/${rowData.reportName}`

        window.open(reportUrl, '_blank');

    }

    return (
        <Card className="utility-report-card" title={
            <div className="utility-report-header">
                <div className="utility-report-title">Utility Reports</div>
            </div>
        }>

            <div className="flex-box justify-content-end">
                <Input.Search
                    allowClear
                    size={"large"}
                    placeholder="Search report"
                    onChange={e => setReportName(e.target.value)}
                    onSearch={value => getPaginatedData(1, value)}
                    enterButton
                    value={reportName}
                    style={{ width: "35%", marginBottom: "18px" }}
                />
            </div>

            <Table bordered={true}
                pagination={{
                    total: utilityReportData.totalCount,
                    pageSize: 10,
                    current: utilityReportData.currentPageNo,
                    onChange: pageNo => onPageChange(pageNo),
                    showTotal: (total, range) => {
                        return <>{range[0]} - {range[1]} of {total}</>
                    }
                }}
                dataSource={utilityReportData.utilityReport} columns={columns} />

        </Card>
    )

}

export default UtilityReport;