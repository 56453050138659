import { Button, Checkbox, DatePicker, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { capitalizeString } from "../../../common/utils/utils";
import CheckboxGroup from "antd/lib/checkbox/Group";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CreateVendor from "../vendor/create-vendor";
import { Constants } from "../../../common/utils/constants";


export default function TripsMenu({ routeOptions, statusOptions, tripFetchType, defaultFilterPayload, SetFetchTripsPayload, fetchTripsPayload, loading}) {

    const [ modalVisible, SetModalVisible ] = useState(false);
    const [ selectedStartDate, SetSelectedStartDate] = useState(undefined);

    const [ filterValues, SetFilterValues ] = useState(fetchTripsPayload);
    const [ originOptions, SetOriginOptions ] = useState([]);
    const [ vendorCreateModelOpen, setVendorCreateModelOpen ] = useState(false);

    const navigate = useNavigate();
    
    const onVendorSuccess = _ => setVendorCreateModelOpen(false);
    const onVendorCancel = _ => setVendorCreateModelOpen(false)
    
    const configurations = localStorage._configrations ? JSON.parse(atob(localStorage._configrations)) : false;
    
    useEffect(() => {
        var _originOptions = [];
        
        routeOptions.map(route => {
            if (!_originOptions.map(o => o.key).includes(route?.sourceLocation?.id)) {
                if (route.sourceLocation) {
                    _originOptions.push({
                        key  : route?.sourceLocation?.id,
                        name : route?.sourceLocation?.name
                    })
                }
            }
        });
        SetOriginOptions(_originOptions);
    }, [ routeOptions ])

    const searchTripByCode = value => {
        SetFetchTripsPayload(curr => ({
            ...curr,
            tripCode : value,
            pageNo   : 1
        }));
    }

    const handleClose = _ => {
        SetModalVisible(false);
    }

    const handleReset = _ => {
        SetFilterValues({ ...defaultFilterPayload })
        SetFetchTripsPayload({ ...defaultFilterPayload });
        SetModalVisible(false);
    }

    const handleOk = _ => {
        SetFetchTripsPayload({...filterValues});
        SetModalVisible(false);
    }

    const handleFilterValueChange = (value, filterKey, isValueToArray) => {
        var tempValue = value;
        if (isValueToArray) {
            tempValue = [ value ];
        }
        SetFilterValues(curr => {
            var _filterValues = { 
                ...curr,
                [filterKey] : tempValue
            };
            if (filterKey === "routeIds") {
                _filterValues.originLocationIds = [routeOptions.find(route => route.id == tempValue[0])?.sourceLocation?.id]
            }
            else if (filterKey === "originLocationIds" && _filterValues?.routeIds?.[0] !== undefined) {
                var tempOrigin = routeOptions.find(route => route.id == _filterValues?.routeIds?.[0])?.sourceLocation?.id
                if(tempOrigin != tempValue[0]) {
                    _filterValues.routeIds = undefined;
                }
            }
            return _filterValues;
        });
    }

    const disableDate = date => {
        if (date.valueOf() > moment().endOf("day").valueOf()) return true;
        if (selectedStartDate === undefined) return false;
        const maxEndDate = moment(selectedStartDate).startOf("day").add(15, "days").endOf("day");
        if ((date.valueOf() >= maxEndDate.valueOf()) || date.valueOf() < moment(selectedStartDate).startOf("day").valueOf()) {
            return true;
        }
        return false;
    }

    return (
        <div className="flex-box flex-gap-m align-items-center justify-content-space-between">
            <div className="flex-box flex-2 flex-gap-m align-items-center">
                <Input.Search onSearch={searchTripByCode} allowClear={true}
                 placeholder="Search Trip No." style={{ maxWidth : 400 }} disabled={loading} />
                <Button className="lsn-btn-primary" icon="filter" disabled={loading}
                 onClick={_ => SetModalVisible(true)}> 
                    Filter 
                </Button>    
            </div>

            <div className="flex-1 flex-box justify-content-flex-end flex-gap-l align-items-center">
                {
                    (tripFetchType == "OUTBOUND_TRIP" &&
                    (configurations.vendorCreationEnabledUsers &&
                        configurations.vendorCreationEnabledUsers.indexOf(Constants.sessionUser?.id + "") > -1 )) ?
                    <>
                        <Button className="lsn-btn-primary" icon="plus"
                         onClick={_ => setVendorCreateModelOpen(true)}> 
                            Create Vendor
                        </Button>    
                        {
                            vendorCreateModelOpen &&  
                            <CreateVendor onVendorSuccess={onVendorSuccess} onVendorCancel = {onVendorCancel}/>
                        }
                    </> :
                    <></>
                }

                {
                    tripFetchType == "OUTBOUND_TRIP" &&
                    <Button className="lsn-btn-primary"
                    onClick={_ => navigate("/appv2/trips/form/outbound/create")}> 
                        Create Trip 
                    </Button>    
                }
            </div>

            <Modal
                title="Filters"
                visible={modalVisible}
                onCancel={handleClose}
                footer={[
                    <Button key="reset" onClick={handleReset}>Reset</Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>Apply</Button>,
                ]}>
                    <div className="flex-column flex-gap-l align-items-center">

                        <div className="filter-modal-item">
                            <strong>Routes</strong>
                            <Select placeholder="Select Route" style={{ width: "100%" }}
                            value={filterValues?.routeIds?.[0]} onChange={key => handleFilterValueChange(key, "routeIds", true)}
                            showSearch={true} allowClear={true}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                            >
                                {
                                    routeOptions.map(route => <Select.Option key={route.id} value={route.id}>{route.name}</Select.Option>)
                                }
                            </Select>
                        </div>

                        {
                            tripFetchType === "INBOUND_TRIP" && 
                            <div className="filter-modal-item">
                                <strong>Origin</strong>
                                <Select placeholder="Select Origin" style={{ width: "100%" }}
                                value={filterValues?.originLocationIds?.[0]} 
                                onChange={key => handleFilterValueChange(key, "originLocationIds", true)}
                                showSearch={true} allowClear={true}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                                >
                                    {
                                        originOptions &&
                                        originOptions.map((loc, i) => <Select.Option key={loc.key} value={loc.key}>{loc?.name}</Select.Option>)
                                    }
                                </Select>
                            </div>
                        }

                        <div className="filter-modal-item">
                            <strong>Status</strong>
                            <CheckboxGroup onChange={e => handleFilterValueChange(e, "tripStatuses", false)} 
                             value={filterValues.tripStatuses} className="lsn-filter-options-checkbox">
                                {
                                    statusOptions.map(status => (
                                        <Checkbox key={status} value={status}>
                                            {capitalizeString(status.toLowerCase())}
                                        </Checkbox>
                                    ))
                                }
                            </CheckboxGroup>
                        </div>
                        

                        <div className="filter-modal-item">
                            <strong>Created Time</strong>
                                <DatePicker.RangePicker disabledDate={disableDate}
                                 format="DD/MM/YYYY" separator={"to"} className="flex-2"
                                 onCalendarChange={dates => {
                                    if (dates.length > 1) {
                                        SetSelectedStartDate(undefined);
                                        return;
                                    }
                                    SetSelectedStartDate(dates[0]);
                                 }}
                                 onChange={dateRange => {
                                    SetFilterValues(curr => {
                                        if (dateRange) {
                                            return {
                                                ...curr,
                                                startDate : dateRange[0] ? moment(dateRange[0]).startOf('day').valueOf() : moment().subtract(7, "days").startOf().valueOf(),
                                                endDate   : dateRange[1] ? moment(dateRange[1]).valueOf() : moment().startOf().valueOf(),
                                            }
                                        }
                                        else {
                                            return {
                                                ...curr,
                                                startDate : moment().subtract(7, "days").startOf().valueOf(),
                                                endDate   : moment().startOf().valueOf()
                                            }
                                        }
                                    })
                                 }}
                                 value={[
                                    filterValues?.startDate ? moment(filterValues?.startDate) : undefined,
                                    filterValues?.endDate ? moment(filterValues?.endDate) : undefined
                                 ]} 
                                />
                        </div>

                    </div>
            </Modal>
        </div>
    )
}