import { Card, Empty, Modal, Pagination, Spin, Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getPostFilterPayload } from "../../../common/utils/apiGateway";
import { downloadFile, notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import LabelRvp from "../../common/label-rvp";
import ReversePickupSparkline from "./ReversePickupSparkline";
import { columns } from "./ReversePickupTableColumns";
import ReversePickupTableMenu from "./ReversePickupTableMenu";
import {
    fetchFilterOptionsData,
    fetchFilteredWaybillList,
    fetchReversePickupSparkline,
    triggerLeadAllocation,
    triggerWaybillListDownload, triggerWaybillScan,
    fetchFEList
} from "./action";
import ActionModal from "./action.modal";
import AssignFEModal from "./assign.modal";
import { SoundNotificationService } from "../../../services/sound.service";
import { useSelector } from "react-redux";
import ScanActionRequiredPopupModal from "../../shared-components/scan-action-required-modal";
import "../../../../src/assets/styles/common.scss";

const soundService = new SoundNotificationService();

const ManageReversePickup = () => {

    const config = useSelector((state) => state?.app?.configurations)

    const defaultFilterPayload = {
        pageNo: 1,
        pageSize: getPostFilterPayload().filters.pageSize,
        createdAt: {
            from: moment().subtract(config.rvp_date_range == null ? 7 : config.rvp_date_range, "days").startOf("day").valueOf(),
            to: moment().endOf("day").valueOf()
        },
        customerIds: [],
        statuses: [],
        waybills: [],
        pincodeIds: [],
        feIds: [],
        packets: [],
        attemptCount: null,
    };



    const [isFilterEnabled, setIsFilterEnabled] = useState(false);
    const [isDateChange, setIsDateChange] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [sparklineInfo, setSparklineInfo] = useState({});
    const [filteredWaybillList, setFilteredWaybillList] = useState({});
    const [filterOptionsData, setFilterOptionsData] = useState({})
    const [lastRefreshed, setLastRefreshed] = useState(moment().valueOf());
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [feUserId, setFeUserId] = useState(undefined);
    const [assignSuccess, setAssignSuccess] = useState(false);
    const [filterPayload, setFilterPayload] = useState(defaultFilterPayload)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [showActionDialog, setShowActionDialog] = useState(false);
    const [selectedActionRow, setSelectedActionRow] = useState({});
    const [printTask, setPrintTask] = useState(false);
    const [waybillScanData, setWaybillScanData] = useState([]);
    const [clearWaybillInput, setClearWaybillInput] = useState(false);
    const [feloading, setFeloading] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const [disableActionButton, setDisableActionButton] = useState(false);
    const [selectedWaybillData, setSelectedWaybillData] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [buttonAction, setButtonAction] = useState(null)
    const [actionUrl, setActionUrl] = useState(undefined);
    const [loadManageReversePickupFilterData, setLoadManageReversePickupFilterData] = useState(true);
    const [sparklineLoader, setSparklineLoader] = useState(false);


    useEffect(() => {
        refreshEntireScreen();
    }, []);


    useEffect(() => {

        if (selectAll) {
            const selectedRowKeyValues = []

            filteredWaybillList?.consignments?.forEach((cgnData, index) => {
                selectedRowKeyValues.push(index);
            });

            setSelectedRowKeys(selectedRowKeyValues)
        }

    }, [filteredWaybillList, selectAll])

    const refreshEntireScreen = () => {
        fetchAllSparklineInfo();
        // fetchAllWaybillList(filterPayload);
        // fetchLeadsFilterData(filterPayload);
        setIsFilterEnabled(true)
        setSelectedRowKeys([])
        setLoadManageReversePickupFilterData(true)
    };

    const fetchAllSparklineInfo = () => {

        setSparklineLoader(true)

        fetchReversePickupSparkline(
            { showLoader: false }
        )
            .then((res) => {
                setSparklineInfo(res)
                setSparklineLoader(false)
            })
            .catch((e) => {
                setSparklineLoader(false)
                notifyApiError("Fetching sparkline data failed", e)
                console.error("Error while calling fetchReversePickupSparkline ", e)
            })
    };

    const fetchAllWaybillList = (filterPayloadReq, fromRefreshListAfterPrint) => {

        fetchFilteredWaybillList(filterPayloadReq)
            .then(resp => {
                setLastRefreshed(moment().valueOf())
                setFilteredWaybillList(resp)

                if (fromRefreshListAfterPrint) {
                    setSelectedActionRow(resp.consignments.find((cgn) => cgn.waybillNumber == selectedActionRow.waybillNumber))
                }
            })
            .catch((e) => {
                notifyApiError("Fetching waybill list failed", e)
                console.error("Error while calling fetchFilteredWaybillList ", e)
            });
    };

    const fetchLeadsFilterData = (filterData) => {

        const payloadData = {
            "dateRange": {
                "from": filterData.createdAt.from,
                "to": filterData.createdAt.to
            }
        }

        fetchFilterOptionsData(payloadData)
            .then((res) => {
                setFilterOptionsData(res)
                setFeloading(false)
                setLoadManageReversePickupFilterData(false)
            })
            .catch((e) => {
                notifyApiError("fetching filter data failed", e)
                console.error("Error while calling fetchFilterOptionsData ", e)
                setFeloading(false)
                setLoadManageReversePickupFilterData(false)
            })

    }

    const handleCancel = e => {
        setActionUrl(undefined)
    };


    const handleActionClick = (actionRow) => {
        setSelectedActionRow(actionRow)
        setShowActionDialog(true)
    }

    const handlePrintTask = () => {
        setPrintTask(false)
        setActionLoader(false)
    }

    const handlePrintLabel = () => {
        // Use this for accessing selected row data.
        setPrintTask(true)
        setActionLoader(true)
    }

    const handleFilterChange = (key, values) => {
        setSelectAll(false)
        setSelectedWaybillData({})
        setSelectedRowKeys([])

        const filterPayloadInfo = {
            ...filterPayload,
            [key]: values,
            pageNo: 1
        }

        if (key == "waybills") {
            filterPayloadInfo["packets"] = []
        } else if (key == "packets") {
            filterPayloadInfo["waybills"] = []
        }

        setFilterPayload(filterPayloadInfo)
        setSelectedRowKeys([])
        if (isSearch === true) {
            fetchAllWaybillList(filterPayloadInfo)
            setIsSearch(false)
        }
    };

    const fetchFilteredWaybillsAfterClick = () => {
        if (isDateChange === true) {
            fetchLeadsFilterData(filterPayload)
        }
        fetchAllWaybillList(filterPayload)
        setIsFilterEnabled(false)
        setIsDateChange(false)
    }

    const resetFilters = _ => {
        setSelectedRowKeys([])
        setSelectAll(false)
        setSelectedWaybillData({})
        setAssignSuccess(false)
        setFilterPayload(defaultFilterPayload)
        // fetchAllSparklineInfo();
        fetchLeadsFilterData(defaultFilterPayload);
        fetchAllWaybillList(defaultFilterPayload);
    }

    const downloadAllLeadInfo = () => {
        setDownloadLoading(true)
        triggerWaybillListDownload(filterPayload)
            .then((res) => {
                downloadFile(res?.s3FileUrl);
                setDownloadLoading(false)
            })
            .catch((e) => {
                notifyApiError("Exporting data failed", e)
                setDownloadLoading(false)
                console.error("Error while calling triggerWaybillListDownload ", e)
            })
    };

    // Triggered when table row is selected or deselected.
    const onRowSelectionChange = (selectedRowKeysInfo) => {

        if (selectAll) {
            return
        }

        const selectedRowKeysInfoSet = new Set(selectedRowKeysInfo)

        const selectedWaybillDataCpy = { ...selectedWaybillData }

        filteredWaybillList?.consignments.map((consignmentData, index) => {

            if (selectedRowKeysInfoSet.has(index)) {
                selectedWaybillDataCpy[consignmentData.waybillNumber] = consignmentData
            } else {
                delete selectedWaybillDataCpy[consignmentData.waybillNumber]
            }

        })

        setSelectedRowKeys(selectedRowKeysInfo)
        setSelectedWaybillData(selectedWaybillDataCpy)

    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onRowSelectionChange,
        // onSelect: _ => {
        //     console.log('onSelect selectedRowKeys', selectedRowKeys)
        //     setSelectedRowKeys(selectedRowKeys)
        // }
    };

    const allocateWaybillToFE = () => {

        setDisableActionButton(true)

        var payload = {}

        let filterData = { ...filterPayload }

        filterData.feIds = []
        filterData.pageNo = 1
        filterData.pageSize = filteredWaybillList.totalCount

        if (selectAll) {
            delete filterData.waybills
        } else {
            filterData['waybills'] = Object.keys(selectedWaybillData)
            filterData['statuses'] = buttonAction == 'reassign' ? ['OFP', 'PCANC'] : ['PCREATED', 'PPEND', 'BOOK']
        }

        payload = {
            filters: filterData,
            feUserId: feUserId,
            lastRefreshed: lastRefreshed
        }

        setFeloading(true)

        triggerLeadAllocation(payload)
            .then((res) => {
                setFeloading(false)
                setIsAssignModalOpen(false)
                const successTitle = res === "Success" ? "FE assigned successfully!" : res;
                Modal.success({
                    title: successTitle,
                    onOk: () => {
                        setSelectedRowKeys([])
                        setFeUserId(undefined)
                        setAssignSuccess(true);
                        setDisableActionButton(false)
                    }
                });
            })
            .catch((e) => {
                setFeloading(false)
                Modal.error({
                    title: "FE Assignment Failed!",
                    onOk: () => {
                        setSelectedRowKeys([])
                        setFeUserId(undefined)
                        setAssignSuccess(true)
                        setDisableActionButton(false)
                    }
                });
                console.error("Error while calling triggerLeadAllocation api ", e)
            })

    };

    const setFilterEnabledButton = (isCreatedAt) => {
        setIsDateChange(isCreatedAt)
        setIsFilterEnabled(true)
    }


    const getPaginatedData = (pageNo, pageSize) => {

        setSelectedRowKeys([])
        setSelectedWaybillData({})

        const filterReq = {
            ...filterPayload, pageNo, pageSize
        }
        setFilterPayload(filterReq)
        fetchAllWaybillList(filterReq)
    };

    const handleActionModalClose = () => {
        setShowActionDialog(false)
        setWaybillScanData([])
    }

    const handleAwbScan = (waybillNo) => {
        setActionLoader(true)

        const req = {
            "request": [
                {
                    "consignmentScan": {
                        "scanType": "IN_SCAN",
                        "scanInputType": "BARCODE_SCANNER",
                        "waybillNo": waybillNo,
                        "isBarcodeScan": false
                    },
                    "type": "IN_SCAN",
                    "referenceId": waybillNo,
                    "isWaybill": true,
                    "shipmentScans": [
                        {
                            "shipmentCodeType": "BARCODE",
                            "scanType": "IN_SCAN",
                            "scanInputType": "BARCODE_SCANNER",
                            "shipmentCode": waybillNo
                        }
                    ]
                }
            ]
        }

        triggerWaybillScan(req)
            .then((res) => {
                if (res?.status?.code == 202) {
                    if (res?.response?.responses[0]?.sync) {
                        notifyApiSuccess("Scanned Successfully")
                        soundService.playSuccess();

                        let requiredScanData = [
                            {
                                title: 'Waybill No',
                                value: res.response.responses[0].awbNo,
                            },
                            {
                                title: "Status",
                                value: (res.response.responses[0].overageStatus ?
                                    res.response.responses[0].overageStatus :
                                    res.response.responses[0]?.consignmentScanBO?.shipmentStatus
                                ),
                                textColor: res.response.responses[0].overageStatus ? "error-tag" : "primary-info-tag"
                            },
                            {
                                title: "Next Location",
                                value: (res.response.responses[0]?.consignmentScanBO?.consignment?.nextLocation?.name ?
                                    res.response.responses[0]?.consignmentScanBO?.consignment?.nextLocation?.name : null)
                            }
                        ]

                        setWaybillScanData(requiredScanData)

                    } else {
                        soundService.playWarning();
                        setWaybillScanData([])
                        notifyApiError(res?.response?.responses[0]?.reason)
                    }
                    refreshEntireScreen()
                } else if (res?.status?.code == 206) {
                    if (res?.response?.responses[0]?.deepLinkUrl) {
                        setShowActionDialog(false)
                        setActionUrl(res?.response?.responses[0]?.deepLinkUrl)
                    }
                } else {
                    soundService.playWarning();
                    setWaybillScanData([])
                    notifyApiError("Failed. PLease contact support", res?.status?.message)
                }

                setClearWaybillInput(!clearWaybillInput)
                setActionLoader(false)
            })
            .catch((e) => {
                setWaybillScanData([])
                notifyApiError("Failed. PLease contact support", e)
                console.error("Error while calling triggerWaybillScan ", e)
                setClearWaybillInput(!clearWaybillInput)
                setActionLoader(false)
                soundService.playWarning();
            })
    }

    const openAssignFEModalEvent = async (assignType) => {

        setButtonAction(assignType)

        setFeloading(true)

        fetchFEList()
            .then(resp => {
                setFilterOptionsData({ ...filterOptionsData, users: resp?.userDetails })
                setFeloading(false)
            })
            .catch(_ => {
                setFeloading(false)
            })

        let totalWaybillCount = 0
        let totalPincodeCount = 0
        let totalConsigneeCount = 0

        if (selectAll) {

            totalWaybillCount = filteredWaybillList.totalCount
            totalPincodeCount = filteredWaybillList.totalPincodes
            totalConsigneeCount = filteredWaybillList.totalConsignees

        } else {

            let totalPincodeCountSet = new Set()
            let totalConsigneeCountSet = new Set()

            const selectedWaybill = Object.values(selectedWaybillData)

            selectedWaybill.forEach(waybillData => {

                totalPincodeCountSet.add(waybillData.pincode)
                totalConsigneeCountSet.add(waybillData.consigneeId)

            });

            totalWaybillCount = selectedWaybill.length
            totalPincodeCount = totalPincodeCountSet.size
            totalConsigneeCount = totalConsigneeCountSet.size

        }

        const waybillData = { ...filteredWaybillList }

        waybillData["countData"] = {
            totalWaybillCount: totalWaybillCount,
            totalPincodeCount: totalPincodeCount,
            totalConsigneeCount: totalConsigneeCount
        }

        setFilteredWaybillList(waybillData)

        setIsAssignModalOpen(true)

    }

    const refreshListAfterPrint = () => {
        if (!selectedActionRow?.showScanButton) {
            fetchAllWaybillList(filterPayload, true)
        }
    }

    const handleSelectAllAction = () => {
        if (selectAll) {
            setSelectedRowKeys([])
            setSelectedWaybillData({})
        }
        setSelectAll(!selectAll)
    }

    return (
        <>
            <Card className="remove-card-body-padding">
                <div className="flex-column white-bg flex-gap-m">
                    <Spin spinning={sparklineLoader}>
                        <ReversePickupSparkline keyReference={{
                            "unassigned": "Unassigned Pickups",
                            "assignedToday": "Assigned Today",
                            "outForPickup": "Out For Pickup",
                            "notPickedUpToday": "Not Picked Up Today",
                            "pickedUpToday": "Picked Up Today",
                            "cancelledToday": "Cancelled Today",
                            "receivedToday": "Received Today"
                        }} dataStyleObj={{
                            "unassigned": { color: "#FF9801" },
                            "assignedToday": { color: "#0D29DD" },
                            "outForPickup": { color: "#1C6CE1" },
                            "notPickedUpToday": { color: "#F44337" },
                            "pickedUpToday": { color: "#B33FB5" },
                            "cancelledToday": { color: "#F44337" },
                            "receivedToday": { color: "#50B154" },
                        }} dataObject={sparklineInfo} lastRefreshed={lastRefreshed} refreshLeadInfo={refreshEntireScreen} />
                    </Spin>

                    <div className="spacer-hs">
                        <fieldset className="default-fieldset">
                            <legend>Reverse Pickup List</legend>
                            <ReversePickupTableMenu
                                downloadLoading={downloadLoading}
                                filteredLeadData={filterOptionsData}
                                filterPayload={filterPayload}
                                selectedLength={selectAll ? filteredWaybillList?.totalCount : Object.keys(selectedWaybillData).length}
                                totalWaybillCnt={filteredWaybillList?.totalCount}
                                assignSuccess={assignSuccess}
                                setFilterEnabledButton={setFilterEnabledButton}
                                handleFilterChange={handleFilterChange}
                                resetFilters={resetFilters}
                                downloadAllLeadInfo={downloadAllLeadInfo}
                                openAssignFEModal={openAssignFEModalEvent}
                                onInputSearch={(value, searchRadioInput) => {
                                    var inputArray = [];
                                    if (typeof value === "string" && value.trim() !== "") {
                                        inputArray = value.split(/[,\n\s]+/)
                                            .map((w) => {
                                                const inputElem = w.trim()
                                                if (inputElem != "") {
                                                    return w.trim()
                                                }
                                            });
                                    }
                                    handleFilterChange(searchRadioInput, inputArray);
                                }}
                                handleSelectAll={handleSelectAllAction}
                                selectAll={selectAll}
                                selectedWaybillData={Object.values(selectedWaybillData)}
                                isFilterApplied={isFilterEnabled}
                                dateRange={config.rvp_date_range == null ? 7 : config.rvp_date_range}
                                setIsSearch={setIsSearch}
                                loadManageReversePickupFilterData={loadManageReversePickupFilterData}
                                fetchLeadsFilterData={fetchLeadsFilterData}
                            />
                            <Spin spinning={isFilterEnabled}
                                indicator={isFilterEnabled ? <div><Button size="large" type="primary"
                                    className="load-btn" onClick={() => fetchFilteredWaybillsAfterClick()}>
                                    Apply Filters </Button></div> : undefined} >
                                <div className="flex-column flex-gap-m spacer-hm mt-8">
                                    <Table style={{ borderRadius: 10 }} bordered={true} columns={columns(handleActionClick)}
                                        rowSelection={rowSelection} size="middle"
                                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}
                                        dataSource={filteredWaybillList?.consignments}
                                        pagination={false} scroll={{ x: true }}
                                    />
                                    <Pagination className="flex-box justify-content-flex-end" onChange={getPaginatedData}
                                        total={!!filteredWaybillList?.totalCount ? filteredWaybillList?.totalCount : 0}
                                        current={!!filteredWaybillList?.pageNo ? filteredWaybillList?.pageNo : 0}
                                        // pageSize={!!filteredWaybillList?.pageSize ? filteredWaybillList?.pageSize : 0}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                                        onShowSizeChange={getPaginatedData} pageSizeOptions={['10', '20', '30', '40', '50']}
                                        showSizeChanger />
                                </div>
                            </Spin>

                        </fieldset>
                    </div>
                </div>
            </Card >
            {isAssignModalOpen &&
                <AssignFEModal
                    isAssignModalOpen={isAssignModalOpen}
                    feUserId={feUserId}
                    feList={filterOptionsData?.users} filteredLeadData={filteredWaybillList}
                    onFeChange={feUserId => setFeUserId(feUserId)}
                    allocateWaybillToFE={_ => allocateWaybillToFE()}
                    onAssignCancel={_ => {
                        setIsAssignModalOpen(false)
                        setFeUserId(undefined)
                    }}
                    feloading={feloading}
                    isFeButtonDisabled={disableActionButton}
                />
            }
            {
                showActionDialog &&
                <ActionModal open={showActionDialog} onClose={handleActionModalClose}
                    modalData={selectedActionRow} handleAwbScan={handleAwbScan}
                    handlePrintLabel={_ => handlePrintLabel()}
                    waybillScanData={waybillScanData}
                    clearWaybillInput={clearWaybillInput}
                    actionLoader={actionLoader}
                />
            }

            {
                printTask &&
                <LabelRvp waybillNo={selectedActionRow?.waybillNumber} toPrint={printTask}
                    handlePrintTask={_ => handlePrintTask()} refreshList={_ => refreshListAfterPrint()} />
            }
            <ScanActionRequiredPopupModal handleClose={handleCancel}
                open={actionUrl ? true : false}
                qrText={actionUrl}
            />
        </>
    )
}

export default ManageReversePickup;